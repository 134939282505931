import React from 'react';
import LandbotComponent from './LandbotComponent';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
       <audio autoPlay>
       <source src="https://www.abcontrol.es/Aby/Aby_v1/assets/welcome.mp3" type="audio/mpeg" />
        Tu navegador no admite la reproducción de audio.
      </audio>
      <Helmet>
      <link rel="icon" type="image/ico" href="https://www.laveuesmagica.cat/favicon.ico" />
     
      </Helmet>
      <LandbotComponent />
      
    </div>
  );
}

export default App;
