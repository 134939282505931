import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const LandbotComponent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
    script.setAttribute('SameSite', 'None; Secure');
    document.body.appendChild(script);

    script.onload = () => {
      // Este código se ejecutará una vez que se haya cargado el script.
      var myLandbot = new window.Landbot.Fullpage({
        configUrl: 'https://storage.googleapis.com/landbot.pro/v3/H-1692649-QKIK3WCBELYMN0QQ/index.json',
      });
    };

    return () => {
      // Limpia el script cuando el componente se desmonta.
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <title>La veu és màgica | Blanca AI</title>
      </Helmet>
      <div id="landbot-container"></div>
    </>
  );
};

export default LandbotComponent;


